<template>
  <div class="">
    <el-dialog
      :visible.sync="dialogVisible"
      width="50%"
      @close="handleClose"
      custom-class="diag"
    >
      <h2 slot="title">闯关模式说明</h2>
      <h3>Q：为什么有的课件加锁，无法学习？</h3>
      <p>
        A：因为当前课程为闯关模式，需要按照顺序进行学习，学习了上一个课件，才能解锁下一个课件
      </p>
      <h3>Q：解锁后，课件能任意学习吗？</h3>
      <p>A： 解锁所有课件后，即可任意学习啦</p>
      <h3>Q：为什么有的课件显示了学习进度，但是却是加锁状态？</h3>
      <p>
        A：因为老师修改了课件学习顺序，只要将未学完课件按照顺序学完后，之前已学的课件进度也是同步的~不用担心重复学习
      </p>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      dialogVisible: false,
    };
  },
  mounted() {},
  methods: {
    showdiag() {
      this.dialogVisible = true;
    },
    handleClose() {
      this.dialogVisible = false;
    },
  },
};
</script>

<style scoped lang="scss">
/deep/.diag {
  border-radius: 16px;
  .el-dialog__header {
    border-bottom: 1px solid #eee;
  }
  h2 {
    font-size: 16px;
  }
  h3,
  p {
    font-size: 14px;
    line-height: 30px;
  }
  p {
    margin-bottom: 20px;
  }
}
</style>
