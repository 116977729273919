<template>
  <div class="course_material">
    <div v-if="tableData.length > 0 || dirid">
      <ul class="course_materialoul1 clearfix">
        <li class="course_materialoul1oli1">资料名称</li>
        <li class="course_materialoul1oli2">上传时间</li>
        <li class="course_materialoul1oli3">文件大小</li>
        <li class="course_materialoul1oli4">操作</li>
      </ul>
      <div class="course_materialqh" v-if="showicon">
        <span @click="backgo"
          ><img src="../assets/images/offcn_yxbbg22.png" alt=""
        /></span>
        <!--        <span><img src="../assets/images/offcn_yxbbg23.png" alt="" /></span>-->
      </div>

      <div class="course_materiallist" style="width: 100%">
        <table width="100%">
          <tr v-for="(item, index) in tableData" :key="index">
            <td height="60">
              <div class="course_materiallisttit">
                <img
                  :src="
                    require('../assets/images/icon_' +
                      (item.hasOwnProperty('ext') ? item.ext : 'file') +
                      '.png')
                  "
                  alt=""
                />
                <span>{{ item.name }}</span>
              </div>
            </td>
            <td width="320">
              <p class="course_materiallistp">
                {{ item.creat_time | formatimes("yyyy-MM-dd hh:mm:ss") }}
              </p>
            </td>
            <td width="196">
              <p class="course_materiallistp">
                {{ item.file_size | byteConvert }}
              </p>
            </td>
            <td width="70">
              <el-button
                @click="handledown(item)"
                class="course_materiallistdown"
                v-if="item.hasOwnProperty('ext')"
                >下载</el-button
              >
              <el-button
                v-else
                @click="handleEdit(item)"
                class="course_materiallistopen"
                >打开</el-button
              >
            </td>
          </tr>
        </table>
      </div>
    </div>
    <div class="course_materialzw" v-if="tableData.length == 0">
      <h6><img src="../assets/images/offcn_yxbbg24.png" alt="" /></h6>
      <p>暂无资料</p>
    </div>

    <el-table v-if="excelData.length" :data="excelData" style="width: 100%">
      <el-table-column
              v-for="(value, key, index) in excelData[2]"
              :key="index"
              :prop="key"
              :label="key"
      ></el-table-column>
    </el-table>
  </div>
</template>

<script>
import { fileList, downFiles } from "../assets/api.js";
export default {
  name: "course_material",
  props: ["ssid"],
  data() {
    return {
      excelData: [],
      workbook: {},
      tableData: [],
      showicon: false,
      sou_school_id: "",
      secondFlag: false,
      dirid: 0
    };
  },
  mounted() {
    this.getList(0);
  },
  methods: {
    getList(dirid) {
      this.dirid = dirid;
      let buck = this.ssid;
      let params = {
        course_id: this.$route.query.course_id,
        dir_upload_id: dirid,
        bucket_name: "uxiaobang-" + buck + "-1251849375",
        type: 1,
      };
      fileList(params).then((res) => {
        if (res.code == 0) {
          if (res.data.file_list.length > 0) {
            res.data.file_list.map((item) => {
              item.name = item.file_name;
            });
          }
          if (res.data.dir.length == 0) {
            this.tableData = res.data.file_list;
          } else if (res.data.file_list.length == 0) {
            this.tableData = res.data.dir;
          } else {
            this.tableData = res.data.dir.concat(res.data.file_list);
          }
        }
      });
    },
    backgo() {
      this.getList(0);
      this.showicon = false;
    },
    handleEdit(row) {
      this.showicon = true;
      this.tableData = [];
      this.secondFlag = true;
      this.getList(row.id);
    },
    handledown(row) {
      let params = {
        file_id: row.id,
        course_id: row.course_id,
        file_type: 1,
      };
      downFiles(params).then((res) => {
        if (res.code == 0) {
          window.location.href = res.data.inline_url;
        }
      });
    },
  },
};
</script>

<style scoped lang="scss">
//加载更多
.course_MyCreditsfrdivmore {
  width: 102px;
  height: 30px;
  border: 1px solid #e1e1e1;
  border-radius: 15px;
  text-align: center;
  margin: 50px auto 0;
  color: #666666;
  cursor: pointer;
}
.course_material {
  padding: 40px;
}
.course_materialoul1 {
  border-bottom: 1px solid #eeeeee;
  width: 100%;
  min-width: 900px;
  li {
    float: left;
    font: 14px/46px "Microsoft YaHei";
    color: #333333;
  }
  .course_materialoul1oli1 {
    width: calc(100% - 586px);
  }
  .course_materialoul1oli2 {
    width: 320px;
  }
  .course_materialoul1oli3 {
    width: 196px;
  }
  .course_materialoul1oli4 {
    width: 70px;
  }
}
.course_materialqh {
  font-size: 0;
  margin: 14px 0 0 0;
  span {
    margin-right: 5px;
    cursor: pointer;
  }
}
/deep/.course_materiallist {
  .el-table::before {
    height: 0;
  }
  .el-table .cell {
    padding: 0;
  }
  .el-table th {
    display: none;
  }
  .el-table td,
  .el-table th.is-leaf {
    border-bottom: none;
  }
  .el-table td {
    padding: 20px 0;
  }
  .el-table--enable-row-hover .el-table__body tr:hover > td {
    background: none;
  }
  .course_materiallisttit {
    padding-right: 30px;
    font: 16px/26px "Microsoft YaHei";
    color: #333333;
    height: 26px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    img {
      margin-right: 14px;
    }
  }
  .course_materiallistp {
    padding-right: 30px;
    font: 14px/26px "Microsoft YaHei";
    color: #999;
    height: 26px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .course_materiallistopen,.course_materiallistdown {
    width: 68px;
    height: 32px;
    border-radius: 16px;
    background:#fff;border:1px solid var(--change-color);
    color: var(--change-color);
    font: 14px/32px "Microsoft YaHei";
    text-align: center;
    float: left;
    padding: 0;
  }
  .course_materiallistopen:hover,.course_materiallistdown:hover {
    background: var(--change-color);
    color:#fff;
  }
}
.offcn_more {
  text-align: center;
  margin-top: 22px;
  font: 14px/20px "Microsoft YaHei";
  color: #666;
  i {
    font-size: 12px;
    margin-right: 5px;
  }
  span {
    cursor: pointer;
  }
}
.course_materialzw {
  text-align: center;
  padding-top: 94px;
  padding-bottom: 180px;
  p {
    font: 16px/20px "Microsoft YaHei";
    color: #999;
    margin-top: 20px;
  }
}
.size1200 {
  .course_materialoul1 {
    .course_materialoul1oli1 {
      width: 616px;
    }
    .course_materialoul1oli2 {
      width: 300px;
    }
    .course_materialoul1oli3 {
      width: 216px;
    }
    .course_materialoul1oli4 {
      width: 68px;
    }
  }
}
</style>
