<template>
  <div class="deleteWrap">
    <el-dialog
      width="400px"
      :visible.sync="dialogVisible"
      center
      :show-close="false"
    >
      <div class="textWrap">
        <p>确定要删除这篇笔记吗？</p>
        <p>删除后无法恢复，谨慎操作哦</p>
      </div>
      <div class="btnWrap">
        <el-button type="info" round @click="dialogVisible = false"
          >取消</el-button
        >
        <el-button type="primary" round @click="submit">清空</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { noteDel } from "../../assets/api";
export default {
  data() {
    return {
      dialogVisible: false,
    };
  },
  mounted() {},
  methods: {
    openInit(id) {
      this.dialogVisible = true;
      this.notes_id = id;
    },
    submit() {
      const params = {
        notes_id: this.notes_id,
      };
      noteDel(params).then((res) => {
        if (res.code == 0) {
          this.dialogVisible = false;
          this.$parent.getList(1);
        }
      });
    },
  },
};
</script>

<style scoped lang="scss">
.deleteWrap {
  /deep/.el-dialog {
    border-radius: 16px;
  }
  .textWrap {
    text-align: center;
    margin-bottom: 50px;
    font-size: 14px;
    color: #777;
    p:nth-child(1) {
      font-size: 18px;
      margin-bottom: 12px;
      color: #333;
    }
  }
  .btnWrap {
    text-align: center;
    .el-button.is-round {
      padding: 12px 45px;
    }
    .el-button--info {
      background: #eeeeee;
      border: 1px solid #eeeeee;
      color: #666;
      &:hover,
      &:focus {
        background: #ddd;
      }
    }
  }
}
</style>
