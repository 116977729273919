<template>
	<div class="noteWrap">
		<div v-if="!addFlag">
			<div class="titleWrap mb20">
				<h2>课程笔记</h2>
				<el-button type="primary" round @click="addNote"
					><i class="el-icon-circle-plus-outline"></i>
					新建笔记</el-button
				>
			</div>
			<div class="noDataWrap" v-if="noteList.length == 0">
				<img src="@/assets/images/noData.png" alt="" />
				<p>暂无读书笔记，快去学习吧~</p>
			</div>
			<div v-else>
				<div
					v-for="(item, index) in noteList"
					:key="index"
					class="itemWrap"
				>
					<div class="timeWrap">
						<span>{{ item.add_time }}</span>
						<div v-if="!item.isEdit">
							<span @click="deleteNote(item)"
								><i class="iconfont icon-study_del"></i
								>删除</span
							>
							<span @click="editNote(item)"
								><i class="iconfont icon-study_bj1"></i
								>编辑</span
							>
						</div>
					</div>
					<div
						class="contentWrap"
						:class="item.isHeight ? 'heightAuto' : ''"
						v-if="!item.isEdit"
					>
						<div v-html="item.content"></div>
					</div>
					<div v-if="item.isEdit">
						<VueUeditorWrap
							ref="VueUeditorWrap"
							:content="item.content"
						></VueUeditorWrap>
					</div>
					<div class="sourceWrap">
						<div v-if="!item.isEdit">
							<div class="showWrap" v-if="!item.isShow">
								<span
									v-if="!item.isHeight"
									@click="changeShow(item)"
								>
									查看全部<i
										class="iconfont icon-xialajiantouxiao"
									></i
								></span>
								<span v-else @click="changeShow(item)">
									收起<i
										class="iconfont icon-shouqijiantouxiao"
									></i
								></span>
							</div>
						</div>
						<div v-else class="editBtnWrap">
							<el-button
								type="info"
								round
								@click="item.isEdit = !item.isEdit"
								>取消</el-button
							>
							<el-button
								type="primary"
								round
								@click="submitNote(item)"
								>提交</el-button
							>
						</div>
					</div>
				</div>
				<p class="moreBtn" @click="getMore">
					<i class="iconfont icon-more_btn mr5"></i>{{ btnMessage }}
				</p>
			</div>
		</div>
		<div v-else>
			<NoteAdd ref="NoteAdd"></NoteAdd>
		</div>
		<NoteDelete ref="NoteDelete"></NoteDelete>
	</div>
</template>

<script>
import { noteList, noteEdit } from '../assets/api';

import VueUeditorWrap from '@/views/components/ueditor/ueditor.vue';
import NoteAdd from './noteCom/courseNoteAdd.vue';
import NoteDelete from './noteCom/noteDelete.vue';
export default {
	components: {
		VueUeditorWrap,
		NoteAdd,
		NoteDelete,
	},
	data() {
		return {
			noteList: [],
			page: 1,
			limit: 20,
			addFlag: false,
			btnMessage: '加载更多',
			uuid0: [],
			uuid1: [],
		};
	},
	mounted() {
		this.getList(1);
	},
	methods: {
		getList(type) {
			if (type == 1) {
				this.page = 1;
				this.noteList = [];
			}
			const params = {
				limit: this.limit,
				page: this.page,
				course_id: this.$route.query.course_id,
			};
			noteList(params).then((res) => {
				if (res.code == 0) {
					if (res.data != null) {
						if (type == 1) {
							this.noteList = res.data;
						} else {
							this.noteList = this.noteList.concat(res.data);
						}
						if (this.noteList.length > 0) {
							setTimeout(() => {
								this.noteList.forEach((item, index) => {
									if (
										document.getElementsByClassName(
											'contentWrap'
										)[index].offsetHeight >=
										document.getElementsByClassName(
											'contentWrap'
										)[index].childNodes[0].offsetHeight
									) {
										this.$set(item, 'isShow', true);
									} else {
										this.$set(item, 'isShow', false);
									}
									this.$set(item, 'isHeight', false);
									this.$set(item, 'isEdit', false);
								});
							}, 30);
						}
					} else {
						this.btnMessage = '暂无更多';
					}
				} else {
					this.page--;
				}
			});
		},
		getMore() {
			if (this.btnMessage == '加载更多') {
				this.page++;
				this.getList(2);
			}
		},
		changeShow(item) {
			item.isHeight = !item.isHeight;
		},
		deleteNote(item) {
			this.$refs.NoteDelete.openInit(item.id);
		},
		addNote() {
			this.addFlag = true;
		},
		editNote(item) {
			this.noteList.map((item) => {
				item.isEdit = false;
			});
			item.isEdit = true;
		},
		submitNote(item) {
			if (this.$refs.VueUeditorWrap[0].editorData == '') {
				this.$message.error('笔记内容不能为空');
			} else {
				const params = {
					content: this.$refs.VueUeditorWrap[0].editorData,
					notes_id: item.id,
					uuid0: this.uuid0 != null ? this.uuid0.toString() : '',
					uuid1: this.uuid1 != null ? this.uuid1.toString() : '',
				};
				console.log(params);
				noteEdit(params).then((res) => {
					if (res.code == 0) {
						item.isEdit = false;
						this.getList(1);
					}
				});
			}
		},
	},
};
</script>

<style scoped lang="scss">
.noteWrap {
	padding: 20px 30px;
	box-sizing: border-box;
	.noDataWrap {
		text-align: center;
		margin: 100px 113px;
		p {
			margin: 25px 0;
			font-size: 16px;
			color: #999999;
		}
	}
	.titleWrap {
		display: flex;
		align-items: center;
		justify-content: space-between;
		i {
			font-size: 16px;
		}
		.el-button.is-round {
			padding: 10px 20px;
		}
	}
	.itemWrap {
		padding: 30px 0;
		border-bottom: 1px solid #e9eaeb;
		color: #888888;
		font-size: 14px;
		.timeWrap {
			margin-bottom: 20px;
			display: flex;
			align-items: center;
			justify-content: space-between;
			color: #888888;
			font-size: 14px;
			i {
				font-size: 16px;
				margin-right: 5px;
			}
			& > div > span {
				margin-left: 25px;
				cursor: pointer;
			}
		}
		.contentWrap {
			font-size: 16px;
			color: #333;
			max-height: 240px;
			overflow: hidden;
		}
		.heightAuto {
			max-height: 100%;
		}

		.sourceWrap {
			overflow: hidden;
			.fromWrap {
				margin-top: 15px;
				display: flex;
				align-items: center;
				float: left;
				i {
					width: 5px;
					height: 5px;
					background: #aaaaaa;
					border-radius: 50%;
					margin-right: 10px;
				}
			}
			.showWrap {
				float: right;
				margin-top: 15px;
				color: var(--default-color);
				cursor: pointer;
				i {
					margin-left: 4px;
				}
			}
			.editBtnWrap {
				float: right;
				margin-top: 15px;
				.el-button.is-round {
					padding: 10px 24px;
				}
				.el-button--info {
					background: #eeeeee;
					border: 1px solid #eeeeee;
					color: #666;
					&:hover,
					&:focus {
						background: #ddd;
					}
				}
			}
		}
	}
	.pageWrap {
		margin-top: 20px;
		float: right;
		padding-bottom: 20px;
		display: flex;
		justify-content: flex-end;
	}
}
.moreBtn {
	margin: 0 auto;
	margin-top: 30px;
	width: 100px;
	height: 30px;
	font-size: 14px;
	border-radius: 18px;
	border: 1px solid #e1e1e1;
	text-align: center;
	line-height: 30px;
	cursor: pointer;
	color: #666;
	&:hover {
		background: var(--change-color);
		border: 1px solid var(--change-color);
		color: #fff;
	}
	i {
		font-size: 14px;
	}
}
</style>
