<template>
  <div class="evalWrap">
    <!-- <div class="noStandWrap mb20" v-if="finish_rate < 30">
      <img src="../assets/images/noStand.png" alt="" />
      <p>课程完成率未达30%，暂时无法对课程进行评价哦</p>
    </div> -->

    <div class="standWrap" v-if="is_expired == 0&&in_list!=0">
      <div class="starWrap">
        <span>留下您对课程的评价吧</span>
        <i
          class="iconfont icon-grayStar"
          :class="nowStar >= item ? 'on' : ''"
          v-for="(item, index) in 5"
          :key="index"
          @click="evalStar(item)"
        ></i>
      </div>
      <div>
        <el-input
          type="textarea"
          placeholder="输入评价内容"
          v-model="content"
          maxlength="200"
          show-word-limit
          :rows="5"
          class="inputWrap"
        ></el-input>
      </div>
      <div class="mt15 btnWrap">
        <el-checkbox v-model="is_anonymous">匿名</el-checkbox>
        <el-button type="primary" round @click="add" >评价</el-button>
      </div>
    </div>
    <div class="noDataWrap" v-if="evalList.length == 0">
      <img src="../assets/images/noData.png" alt="" />
      <p class="mt30">暂无评价</p>
    </div>
    <div class="contentWrap" v-else>
      <div class="titleWrap">
        <div class="leftTitle">
          <img src="../assets/images/evalIcon.png" alt="" />
          全部评价
        </div>
        <div class="rightTitle">
          课程星级<span>{{ appraisal_star }}</span>
        </div>
      </div>
      <div>
        <div class="itemWrap" v-for="(item, index) in evalList" :key="index">
          <div class="infoWrap">
            <div class="leftInfo">
              <img v-if="item.head_pic" :src="item.head_pic" alt="" />
              <img v-else src="@/assets/images/07.png" alt="">
              <div class="nameWrap">
                <p>{{ item.name==''?'匿名用户':item.name }}</p>
                <span>{{ item.add_time | formatimes('yyyy-MM-dd hh:mm:ss')}}</span>
              </div>
            </div>
            <div class="rightInfo">
              <i
                class="iconfont icon-grayStar"
                :class="item.star >= it ? 'on' : ''"
                v-for="(it, i) in 5"
                :key="i"
              ></i>
            </div>
          </div>
          <div class="evalMainWrap">
            <div v-if="item.content">{{ item.content }}</div>
            <p v-else>未填写评价</p>
          </div>
          <div v-if="item.is_reply == 1" class="replyWrap">
            <div class="replyTitle"><i></i>{{item.teacher_name}}回复  {{ item.last_time | formatimes('yyyy-MM-dd hh:mm:ss')}}</div>
            <div class="replyMain">{{ item.reply }}</div>
          </div>
        </div>
        <p class="moreBtn" @click="getMore">
          <i class="iconfont icon-more_btn mr5"></i>{{ btnMessage }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { evalList, evalAdd } from "../assets/api";
export default {
  props: ["is_expired", "appraisal_star",'in_list'],
  data() {
    return {
      userId: "",
      nowStar: "",
      content: "",
      is_anonymous: false,
      page: 1,
      btnMessage: "加载更多",
      evalList: []
    };
  },
  mounted() {
    this.userId = JSON.parse(window.sessionStorage.getItem("infos")).uid;
    this.getEvalList(1);
  },
  methods: {
    evalStar(item) {
      this.nowStar = item;
    },
    getEvalList(type) {
      const params = {
        course_id: this.$route.query.course_id,
        page: this.page,
      };
      evalList(params).then((res) => {
        if (res.code == 0) {
          if(res.data.list.length!=0){
            if(type==1){
              this.evalList=res.data.list
            }else{
            this.evalList =this.evalList.concat(res.data.list);}
          }else{
            this.btnMessage = "暂无更多";
          }
        } else {
          this.page--;
        }
      });
    },
    getMore() {
      if (this.btnMessage == "加载更多") {
        this.page++;
        this.getEvalList(2);
      }
    },
    add() {
      if(this.nowStar==''){
        this.$message.error('请先对课程进行星级评分')
      }else{
        const params = {
        star: this.nowStar,
        course_id: this.$route.query.course_id,
        content: this.content,
        is_anonymous: this.is_anonymous ? 1 : 0,
      };
      evalAdd(params).then((res) => {
        if (res.code == 0) {
          this.nowStar = "";
          this.content = "";
          this.is_anonymous = false;
          this.getEvalList(1);
        }
      });
      }
      
    },
  },
};
</script>

<style scoped lang="scss">
.evalWrap {
  padding: 30px 40px;
  box-sizing: border-box;
  .noStandWrap {
    background: #f9f9f9;
    border-radius: 6px;
    padding: 42px 0 30px;
    box-sizing: border-box;
    text-align: center;
    font-size: 14px;
    color: #666666;
    img {
      margin-bottom: 20px;
    }
  }
  .standWrap {
    font-size: 16px;
    color: #333;
    .starWrap {
      margin-bottom: 30px;
      display:flex;
      align-items: center;
      span {
        margin-right: 15px;
      }
      i {
        font-size: 23px;
        color: #dcdcdc;
        margin-right: 10px;
        cursor: pointer;
      }
      .on {
        color: #ff9914;
      }
    }
    .inputWrap {
      /deep/.el-textarea__inner {
        border-radius: 6px;
        font-size: 16px;
        padding: 16px;
      }
    }
    .btnWrap {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      /deep/.el-checkbox {
        font-size: 16px;
      }
      /deep/.el-button {
        margin-left: 35px;
        font-size: 16px;
        padding: 10px 20px;
      }
    }
  }
  .noDataWrap {
    text-align: center;
    margin-top: 115px;
    margin-bottom: 267px;
    font-size: 16px;
    color: #999999;
  }
  .contentWrap {
    .titleWrap {
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 14px;
      .leftTitle {
        display: flex;
        align-items: center;
        font-weight: bold;
        font-size: 16px;
        img {
          margin-right: 10px;
        }
      }
      .rightTitle {
        span {
          margin-left: 10px;
          font-weight: bold;
          font-size: 18px;
          color: #ff9914;
        }
      }
    }
    .itemWrap {
      padding: 40px 0;
      border-bottom: 1px dashed #e1e1e1;
      .infoWrap {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 20px;
        .leftInfo {
          display: flex;
          align-items: center;
          img {
            width: 44px;
            height: 44px;
            border-radius: 50%;
            margin-right: 10px;
          }
          .nameWrap {
            font-size: 14px;
            color: #999999;
            p {
              margin-bottom: 5px;
              font-size: 16px;
              color: #333;
            }
            .selfWrap {
              color: #507fff;
            }
          }
        }
        .rightInfo {
          i {
            margin-right: 6px;
            font-size: 20px;
            color: #dcdcdc;
          }
          .on {
            color: #ff9914;
          }
        }
      }
      .evalMainWrap {
        font-size: 16px;
        line-height: 32px;
        color: #333333;
        p {
          color: #999999;
        }
      }
      .replyWrap {
        margin-top: 12px;
        background: #f9f9f9;
        border-radius: 6px;
        padding: 24px;
        box-sizing: border-box;
        position: relative;
        &::after {
          clear: both;
          content: "";
          position: absolute;
          left: 24px;
          top: -10px;
          width: 20px;
          height: 20px;
          background: #f9f9f9;
          transform: rotate(45deg);
        }
        .replyTitle {
          display: flex;
          align-items: center;
          font-size: 16px;
          font-weight: bold;
          color: #333;
          i {
            margin-right: 10px;
            background: #507fff;
            width: 6px;
            height: 6px;
            border-radius: 50%;
          }
        }
        .replyMain {
          margin-top: 5px;
          font-size: 16px;
          line-height: 32px;
          color: #333333;
        }
      }
    }
    .moreBtn {
      margin: 0 auto;
      margin-top: 50px;
      width: 100px;
      height: 30px;
      font-size: 14px;
      border-radius: 18px;
      border: 1px solid #e1e1e1;
      text-align: center;
      line-height: 30px;
      cursor: pointer;
      color: #666;
      &:hover{
        background:var(--change-color);
        border:1px solid var(--change-color);
        color:#fff;
      }
      i {
        font-size: 14px;
      }
    }
  }
}
</style>
